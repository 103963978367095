export const ONBOARDING_STEPS = {
  claim: { key: 'claim', link: '/claim' },
  you: { key: 'you', link: '/onboarding/you' },
  template: { key: 'template', link: '/onboarding/template' },
  theme: { key: 'theme', link: '/onboarding/theme' },
  profile: { key: 'profile', link: '/onboarding/profile' },
  socials: { key: 'socials', link: '/onboarding/socials' },
  link: { key: 'link', link: '/onboarding/link' },
  cards: { key: 'cards', link: '/onboarding/cards' },
  background: { key: 'background', link: '/onboarding/background' },
};

export const ONBOARDING_STEPS_ORDER = [
  ONBOARDING_STEPS.claim,
  ONBOARDING_STEPS.you,
  ONBOARDING_STEPS.theme,
  ONBOARDING_STEPS.profile,
  ONBOARDING_STEPS.socials,
  ONBOARDING_STEPS.link,
  ONBOARDING_STEPS.cards,
  ONBOARDING_STEPS.background,
];
