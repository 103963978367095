import React, {
  Dispatch, SetStateAction, useCallback, useEffect,
} from 'react';
import { GoogleToken } from '@typedream/data';
import GoogleSignInScript from '../../head/GoogleSignInScript';
import styles from './GoogleSignInButton.module.css';
import { GOOGLE_CLIENT_ID } from '../../constants';
import useWindowSize from '../../utils/useWindowSize';

export function GoogleSignInButton({ setoauthJWT }: {
  setoauthJWT: Dispatch<SetStateAction<GoogleToken | undefined>>,
}) {
  const size = useWindowSize();

  const renderButton = useCallback(() => {
    if (window.google) {
      const width = size?.width || window?.innerWidth;
      // window.google?.accounts?.id?.prompt(); // also display the One Tap dialog
      window.google?.accounts?.id?.renderButton(
        document.getElementById('buttonDiv'),
        {
          theme: 'filled_blue',
          size: 'large',
          shape: 'pill',
          width: width > 480 ? 400 : width - 50,
        },
      );
    }
  }, [size?.width]);

  useEffect(() => {
    renderButton();
  }, [size?.width, renderButton]);

  function handleCredentialResponse(response: { credential: string }) {
    const credJWT = new GoogleToken(response.credential);
    setoauthJWT(credJWT);
  }

  function loadGoogleSignIn() {
    if (window.google) {
      window.google?.accounts?.id?.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        auto_select: false,
        prompt_parent_id: 'oneTap',
      });
    }
  }

  useEffect(() => {
    if (window && typeof window !== 'undefined') {
      const googleScript = document.getElementById('google-sign-in-script');

      if (window?.google) {
        loadGoogleSignIn();
        renderButton();
      }
      googleScript?.addEventListener('load', () => {
        loadGoogleSignIn();
        renderButton();
      });
    }
  }, []);

  return (
    <>
      <GoogleSignInScript />
      <div id="buttonDiv" className={styles.button} />
    </>
  );
}
