import classNames from 'classnames';
import { PORTAL_ID } from '../../constants';
import { ClientOnlyPortal } from '../ClientOnlyPortal';

export function Loading({ isLoading }: { isLoading: boolean }) {
  if (!isLoading) return null;
  return (
    <ClientOnlyPortal targetId={PORTAL_ID}>
      <div
        className={classNames('loader-wrapper', {
          'is-active': isLoading,
        })}
      >
        <div className="loader-box">
          <div className="loader is-loading" />
        </div>
      </div>
    </ClientOnlyPortal>
  );
}
