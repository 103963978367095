import { validUUID, ISpace } from '@typedream/data';
import { DEFAULT_SPACE_STORAGE_KEY, SPACE_STORAGE_KEY } from '../constants';

export default function getSpacePageID(
  defaultFirstSpace: boolean,
): {
    defaultSpaceSlug?: string;
    defaultSpaceID?: string;
    noPages?: boolean;
    noSpaces: boolean;
  } {
  const spacesStr = localStorage?.getItem(SPACE_STORAGE_KEY);
  let spaces = null;
  if (spacesStr) {
    spaces = JSON.parse(spacesStr);
  }
  if (!spacesStr || !spaces || spaces?.length <= 0) {
    return { noSpaces: true };
  }
  let defaultSpaceID = localStorage?.getItem(DEFAULT_SPACE_STORAGE_KEY) || undefined;
  let defaultSpaceSlug = null;
  let noPages = false;

  if (
    (!defaultSpaceID || defaultSpaceID === 'null' || defaultSpaceID === 'undefined')
    || (!spaces?.map((s: ISpace) => s?.id).includes(defaultSpaceID) && defaultFirstSpace)
  ) {
    defaultSpaceID = spaces && spaces.length > 0 ? spaces[0]?.id : undefined;
    if (defaultSpaceID) {
      localStorage?.setItem(DEFAULT_SPACE_STORAGE_KEY, defaultSpaceID);
    } else {
      localStorage?.removeItem(DEFAULT_SPACE_STORAGE_KEY);
    }
  }
  if (spaces?.length > 0) {
    const defaultSpaceArr = spaces.filter((s: ISpace) => s?.id === defaultSpaceID);
    const defaultSpace = defaultSpaceArr.length > 0 ? defaultSpaceArr[0] : null;
    defaultSpaceSlug = defaultSpace?.slug;
    noPages = !validUUID(defaultSpace?.routes.page_id);
  }
  return {
    defaultSpaceID, defaultSpaceSlug, noSpaces: false, noPages,
  };
}
