import { useCallback } from 'react';
import { useOnboardingStore } from '../store/onboarding';
import { ONBOARDING_STEPS_ORDER } from './onboardingSteps';

export function useRedirectToOnboarding() {
  const { setslug, setspaceID } = useOnboardingStore();
  const redirectToOnboarding = useCallback((spaceID: string, slug: string) => {
    setslug(slug);
    setspaceID(spaceID);
    return `${ONBOARDING_STEPS_ORDER[1].link}`;
  }, []);

  return redirectToOnboarding;
}
