import classNames from 'classnames';

import React, {
  Dispatch,
  SetStateAction, useEffect, useMemo, useRef, useState,
} from 'react';
import styles from './SignupOrLogin.module.css';

export function VerifyCode({
  email,
  err,
  loading,
  onSubmit,
  setErr,
  verify,
}: {
  email: string,
  err: string | null,
  loading: boolean,
  onSubmit: () => void,
  setErr: Dispatch<SetStateAction<any>>,
  verify: (token: string, withOAuth: boolean) => Promise<void>
}) {
  // 30 seconds resend
  const [resendTimer, setResendTimer] = useState(30);
  useEffect(() => {
    // decrement resendTimer every second
    if (resendTimer > 0) { setTimeout(() => setResendTimer((t) => t - 1), 1000); }
  }, [resendTimer]);

  const [pinCodes, setPinCodeLocal] = useState(['', '', '', '', '', '']);
  const setPinCodes = (val: string, index: number) => {
    setErr(null);
    const newVal = [...pinCodes];
    if (val === null || val === undefined) return;
    newVal[index] = val.toUpperCase();
    setPinCodeLocal(newVal);
  };

  const onPasteHandler = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const text = e.clipboardData?.getData('text/plain');
    if (text) {
      const pins = text.replace(/\W/g, '').split('').slice(0, 6);
      setPinCodeLocal(pins);

      let idxInt = pins.length;
      if (pins.length > pinCodes.length) {
        idxInt = pinCodes.length;
      } else if (pins.length <= 0) {
        idxInt = 1;
      }
      const currPin = `pin${idxInt - 1}`;
      document.getElementById(currPin)?.focus();
    }
  };

  const startOfInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (startOfInputRef.current) startOfInputRef.current.focus();
  }, [startOfInputRef]);

  const codeInputField = useMemo(() => pinCodes.map((item, index) => (
    <input
      data-testid={`LoginSignupForm_OTP_input_field_${index}`}
      ref={index === 0 ? startOfInputRef : undefined}
      key={`pinKey${index + 1}`}
      className={styles.pin_input}
      id={`pin${index}`}
      value={item}
      onChange={(e) => {
        if (loading) return;
        setPinCodes((e.nativeEvent as any).data, index);
        if (index + 1 < pinCodes.length) {
          document.getElementById(`pin${index + 1}`)?.focus();
        }
      }}
      onKeyDown={(e) => {
        if (loading) return;
        switch (e.key) {
          case 'ArrowLeft':
            e.preventDefault();
            if (index !== 0) document.getElementById(`pin${index - 1}`)?.focus();
            break;
          case 'ArrowRight':
            e.preventDefault();
            if (index !== pinCodes.length - 1) document.getElementById(`pin${index + 1}`)?.focus();
            break;
          case 'Backspace':
            e.preventDefault();
            if (pinCodes[index] === '') {
              setPinCodes('', index - 1);
              if (index !== 0) document.getElementById(`pin${index - 1}`)?.focus();
              return;
            }
            setPinCodes('', index);
            break;
          default:
            break;
        }
      }}
      type="text"
      autoComplete="one-time-code"
    />
  )), [pinCodes, loading]);

  // verify code whenever these conditions are true
  useEffect(() => {
    if (pinCodes.join('').length !== pinCodes.length) return;
    if (err) return;
    if (loading) return;

    verify(pinCodes.join(''), false);
  }, [pinCodes, err]);

  return (
    <div className={classNames('mb-2 has-text-centered', styles.form)}>
      <div>
        Check your email for a code
      </div>
      <div className="is-size-7 mb-4 has-text-grey">
        We&apos;ve sent a 6-character code to
        {' '}
        <b>{email}</b>
        .
      </div>
      <div data-testid="LoginSignupForm_OTP_input_field" className={styles.pin_row} onPaste={onPasteHandler}>
        {codeInputField.slice(0, 3)}
        <span className="has-text-weight-bold">&#8212;</span>
        {codeInputField.slice(3, 6)}
      </div>
      <div className="is-size-7 mt-3">
        Don&apos;t receive any code?
        {' '}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span
          className={
            classNames({
              'has-text-grey-light': resendTimer > 0,
              [styles.disablePointer]: resendTimer > 0,
              [styles.resend]: resendTimer <= 0,
            })
          }
          role="none"
          onClick={resendTimer <= 0 ? () => { onSubmit(); setResendTimer(30); } : undefined}
        >
          Resend Code
          {' '}
          {resendTimer > 0 && `(${resendTimer} seconds)`}
        </span>
      </div>
    </div>
  );
}
