import {
  useRef, useEffect, useState, PropsWithChildren,
} from 'react';
import { createPortal } from 'react-dom';

type Props = {
  targetId: string
};

export function ClientOnlyPortal({ children, targetId }: PropsWithChildren<Props>) {
  const ref = useRef<HTMLElement | null>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById(targetId);
    if (ref.current) {
      setMounted(true);
    } else {
      console.error("Couldn't find element with ID: ", targetId);
    }
  }, [targetId]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
}
