import classNames from 'classnames';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { AxiosResponse } from 'axios';
import {
  GoogleToken, AccessTokenHandler, STokenHandler, PTokenHandler, errToString, validUUID, ISpace,
} from '@typedream/data';
import { useRouter } from 'next/router';
import {
  PRIVACY_POLICY_LINK,
  PROMO_SOURCE_STORAGE_EXPIRY,
  PROMO_SOURCE_STORAGE_KEY,
  SOURCE_STORAGE_KEY,
  SPACE_PERMISSION_STORAGE_KEY,
  SPACE_STORAGE_KEY, TERMS_OF_SERVICE_LINK, USER_STORAGE_KEY,
} from '../../constants';
import formStyles from '../../styles/Form.module.css';
import { ButtonBlack } from '../Button';
import { GoogleSignInButton } from '../GoogleSignInButton';
import { IUser } from '../../types';
import { setLocalStorageWithExpiry } from '../../utils/localStorageExpire';
import getSpacePageID from '../../utils/getSpacePageID';
import { ONBOARDING_STEPS_ORDER } from '../../utils/onboardingSteps';
import { isValidEmail } from '../../utils/isValidEmail';
import { Loading } from '../Loading';
import { VerifyCode } from './VerifyCode';
import { api } from '../../data/api';
import { ErrorCard } from '../ErrorCard';
import { useRedirectToOnboarding } from '../../utils/useRedirectToOnboarding';

export function SignupOrLogin({
  login,
}: {
  login: boolean,
}) {
  const router = useRouter();
  const [email, setemail] = useState('');
  const [oauthJWT, setoauthJWT] = useState<GoogleToken | undefined>(undefined);
  const [loading, setloading] = useState(false);
  const [errEmail, seterrEmail] = useState(false);
  const [err, seterr] = useState<string | null>(null);
  const [formSuccess, setformSuccess] = useState(false);
  const redirectToOnboarding = useRedirectToOnboarding();

  const verifySuccessHandler = async (r: AxiosResponse, source: string | null) => {
    AccessTokenHandler.storeTokens(r?.data?.token);
    localStorage?.setItem(USER_STORAGE_KEY, JSON.stringify(r?.data?.user));
    localStorage?.setItem(SPACE_STORAGE_KEY, JSON.stringify(r?.data?.spaces));
    localStorage?.setItem(
      SPACE_PERMISSION_STORAGE_KEY,
      JSON.stringify(r?.data?.space_permissions),
    );
    STokenHandler.removeTokens();
    PTokenHandler.removeTokens();

    const user = r?.data?.user as IUser;

    // Just signed up
    if (r?.data?.new) {
      if (source) {
        setLocalStorageWithExpiry(
          PROMO_SOURCE_STORAGE_KEY,
          source,
          PROMO_SOURCE_STORAGE_EXPIRY,
        );
      }

      window.location.href = '/onboarding/hearfrom';
    } else {
      mixpanel.identify(user?.id);
      mixpanel.people.set({
        last_auth: new Date(),
      });

      const { defaultSpaceID, defaultSpaceSlug } = getSpacePageID(
        false,
      );

      mixpanel.identify(user?.id);
      mixpanel.people.set({
        last_auth: new Date(),
      });

      if (!user?.metadata?.hear_from) {
        router.push('/onboarding/hearfrom');
        return;
      }
      if (!r?.data?.spaces || r?.data?.spaces?.length <= 0) {
        router.push(ONBOARDING_STEPS_ORDER[0].link);
        return;
      }
      if (!defaultSpaceID) {
        router.push('/sites');
        return;
      }
      const defaultSpace = r.data.spaces.find((s: ISpace) => s.id === defaultSpaceID);
      if (defaultSpace && !validUUID(defaultSpace.routes.page_id)) {
        router.push(redirectToOnboarding(defaultSpaceID, defaultSpaceSlug!));
        return;
      }
      router.push(`/s/${defaultSpaceSlug}/${defaultSpaceID}`);
    }
  };

  const verify = async (token: string, withOAuth: boolean) => {
    setloading(true);
    const source = localStorage?.getItem(SOURCE_STORAGE_KEY);
    localStorage?.removeItem(SOURCE_STORAGE_KEY);

    const data: {
      email: string,
      token: string,
      source: string,
      tracking_id: string,
      metadata: any,
    } = {
      email,
      token,
      source: '',
      tracking_id: mixpanel.get_distinct_id(),
      metadata: null,
    };

    if (source && source !== 'undefined' && source !== 'null') {
      data.source = source;
    }

    try {
      let r: AxiosResponse<any>;
      if (withOAuth) {
        r = await api().user().verifyGoogle(data);
      } else {
        r = await api().user().verify(data);
      }
      await verifySuccessHandler(r, source);
    } catch (e) {
      seterr(errToString(e));
      setloading(false);
    }
  };

  const onSubmitForm = async () => {
    if (loading) return;
    if (!email && !oauthJWT?.payload?.email) {
      seterrEmail(true);
      return;
    }
    if (email && !isValidEmail(email)) {
      seterrEmail(true);
      seterr('Please enter a valid email');
      return;
    }

    seterrEmail(false);
    setloading(true);
    seterr(null);
    try {
      const data = {
        email: email || oauthJWT?.payload?.email || '',
      };

      if (login) {
        await api().user().login(data, !!oauthJWT?.token);
      } else {
        await api().user().signup(data, !!oauthJWT?.token);
      }

      if (oauthJWT && oauthJWT?.token) {
        verify(oauthJWT?.token, true);
      } else {
        setformSuccess(true);
        setloading(false);
      }
    } catch (e) {
      seterr(errToString(e));
      setformSuccess(false);
      setloading(false);
    }
  };

  useEffect(() => {
    if (oauthJWT?.payload?.email) {
      onSubmitForm();
    }
  }, [oauthJWT]);

  if (formSuccess) {
    return (
      <div className="mt-6">
        <VerifyCode
          email={email}
          err={err}
          loading={loading}
          onSubmit={onSubmitForm}
          setErr={seterr}
          verify={verify}
        />
        <ErrorCard err={err} className="mt-5" />
      </div>
    );
  }
  return (
    <div className={classNames(formStyles.container, 'mt-6 is-flex is-flex-direction-column is-align-items-center')}>
      <div className="is-size-6 mb-1 with-text-color has-fullwidth">Email</div>
      <input
        className={classNames(formStyles.inputFormBorder, 'mb-2', {
          [formStyles.inputError]: errEmail,
        })}
        type="email"
        value={email}
        placeholder="something@example.com"
        required
        onChange={(e) => setemail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmitForm();
          }
        }}
      />
      <ButtonBlack onClick={onSubmitForm} loading={loading}>
        {login ? 'Login' : 'Create Account'}
      </ButtonBlack>

      <div className={classNames(
        'is-size-6 with-lighter-text-color my-3 has-text-centered is-flex is-align-items-center',
        formStyles.or,
      )}
      >
        <hr />
        <span className="mx-3">OR</span>
        <hr />
      </div>

      <GoogleSignInButton setoauthJWT={setoauthJWT} />
      <div
        className="has-text-centered with-lighter-text-color is-size-7 mt-3"
        style={{
          lineHeight: 'normal',
        }}
      >
        By clicking the button above, you agree to our
        {' '}
        <a target="_new" href={TERMS_OF_SERVICE_LINK}>Terms of service</a>
        {' '}
        and
        {' '}
        <a target="_new" href={PRIVACY_POLICY_LINK}>Privacy Policy</a>
      </div>

      <ErrorCard err={err} className="mt-5" />
      <Loading isLoading={loading} />
    </div>
  );
}
