var c = Object.defineProperty;
var n = (o, r, a) => r in o ? c(o, r, { enumerable: !0, configurable: !0, writable: !0, value: a }) : o[r] = a;
var d = (o, r, a) => (n(o, typeof r != "symbol" ? r + "" : r, a), a);
import { JwtToken as u } from "./JwtToken.js";
import { SPACE_ACTION as s, SUBSCRIPTION_PLAN as i } from "../../types.js";
class E extends u {
  constructor(a) {
    super(a);
    d(this, "payload");
    this.payload = this.decodePayload();
  }
  decodePayload() {
    return super.decodePayload();
  }
  getProductID() {
    return this.payload.product_id;
  }
  getSubscriptionID() {
    return this.payload.subscription_id;
  }
  getSpaceID() {
    return this.payload.space_id;
  }
  getStatus() {
    return this.payload.status;
  }
  getPlan(a) {
    return a[this.getProductID()];
  }
  getMetadata() {
    return this.payload.metadata;
  }
  isAllowed(a, l) {
    const t = this.getPlan(l);
    let e = !0;
    switch (a) {
      case s.UpdateAffiliate:
        t === i.FREE && (e = !1);
        break;
      case s.UpdateCustomDomain:
        t === i.FREE && (e = !1);
        break;
      case s.UpdateCustomization:
        t === i.FREE && (e = !1);
        break;
      case s.UpdateMetadata:
        t === i.FREE && (e = !1);
        break;
      case s.UpdateSubdomain:
        t === i.FREE && (e = !1);
        break;
      case s.InviteMember:
        t === i.FREE && (e = !1);
        break;
      case s.ReadBasicAnalytics:
        t === i.FREE && (e = !1);
        break;
      case s.ReadAdvancedAnalytics:
        t === i.FREE && (e = !1);
        break;
      case s.Collection:
        t !== i.PRO && (e = !1);
        break;
      default:
        e = !0;
    }
    return e;
  }
}
export {
  E as SubscriptionToken
};
