import React from 'react';
import Image from 'next/legacy/image';

export function Logo({
  height,
}: {
  height: number
}) {
  return (
    <Image
      src="/images/logo.svg"
      alt="Logo"
      width={height}
      height={height}
    />
  );
}
