import classNames from 'classnames';
import { BiErrorCircle } from 'react-icons/bi';
import styles from './ErrorCard.module.css';

export function ErrorCard({ err, className }: { err: string | null, className?: string }) {
  return err ? (
    <div className={classNames(styles.error, className)}>
      <BiErrorCircle className="with-danger-text-color mr-2" />
      {err}
    </div>
  ) : null;
}

ErrorCard.defaultProps = {
  className: undefined,
};
