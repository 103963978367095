import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { BaseProps } from './Button.types';
import styles from './Button.module.css';

export function ButtonAccent({
  className,
  onClick,
  children,
  disabled,
  loading,
  small,
  rounded,
  ...props
}: PropsWithChildren<{
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
  rounded?: boolean;
}> &
BaseProps) {
  return (
    <button
      type="button"
      className={classNames(
        className,
        styles.buttonBase,
        styles.buttonAccent,
        'button',
        {
          'is-small': small,
          'is-medium': !small,
          [styles.small]: small,
          [styles.buttonRounded]: rounded,
          'is-loading': loading,
        },
      )}
      onClick={disabled || loading ? () => {} : onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
}

ButtonAccent.defaultProps = {
  disabled: false,
  loading: false,
  small: false,
  rounded: false,
  onClick: undefined,
};
