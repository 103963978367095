import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { BaseProps } from './Button.types';
import styles from './Button.module.css';

export function ButtonGreyTransparent({
  className,
  onClick,
  children,
  disabled,
  loading,
  small,
  xSmall,
  rounded,
  ...props
}: PropsWithChildren<{
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
  xSmall? :boolean;
  rounded?: boolean;
}> &
BaseProps) {
  return (
    <button
      type="button"
      className={classNames(
        styles.buttonBase,
        styles.buttonGreyTransparent,
        'button',
        {
          'is-small': small,
          'is-medium': !small,
          [styles.small]: small,
          [styles.xSmall]: xSmall,
          [styles.buttonRounded]: rounded,
          'is-loading': loading,
        },
        className,
      )}
      onClick={disabled || loading ? () => {} : onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
}

ButtonGreyTransparent.defaultProps = {
  disabled: false,
  loading: false,
  small: false,
  rounded: false,
  onClick: undefined,
  xSmall: false,
};
