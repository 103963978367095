import React from 'react';
import Image from 'next/legacy/image';

export function LogoText({
  height,
}: {
  height: number
}) {
  const aspect = 187 / 57;
  return (
    <Image
      src="/images/logo_text.svg"
      alt="LogoText"
      width={aspect * height}
      height={height}
    />
  );
}
