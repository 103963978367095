import create, {
  SetState, GetState, StoreApi, Mutate,
} from 'zustand';
import { subscribeWithSelector, devtools } from 'zustand/middleware';
import { CardLink, IMPORTANT_LINK_OPTIONS, THEME_OPTIONS } from '../../utils/generateTemplate';

type IOnboardingSlice = {
  spaceID: string | undefined;
  slug: string | undefined;
  theme: THEME_OPTIONS | undefined;
  profilePic: string | undefined;
  profileName: string | undefined;
  profileBio: string | undefined;
  instagram: string | undefined;
  tiktok: string | undefined;
  youtube: string | undefined;
  twitter: string | undefined;
  mostImportantLink: IMPORTANT_LINK_OPTIONS | string | undefined;
  card1: CardLink | undefined;
  card2: CardLink | undefined;
  card3: CardLink | undefined;
  setspaceID: (spaceID: string) => void;
  setslug: (slug: string) => void;
  settheme: (theme: THEME_OPTIONS) => void;
  setprofilePic: (profilePic: string) => void;
  setprofileName: (profileName: string) => void;
  setprofileBio: (profileBio: string | undefined) => void;
  setinstagram: (nstagram: string | undefined) => void;
  settiktok: (tiktok: string | undefined) => void;
  setyoutube: (youtube: string | undefined) => void;
  settwitter: (twitter: string | undefined) => void;
  setmostImportantLink: (link: IMPORTANT_LINK_OPTIONS | string | undefined) => void;
  setcard1: (card: CardLink | undefined) => void;
  setcard2: (card: CardLink | undefined) => void;
  setcard3: (card: CardLink | undefined) => void;
  getTemplateArgs: () => {
    slug: string | undefined,
    theme: THEME_OPTIONS | undefined,
    profilePic: string | undefined,
    profileName: string | undefined,
    profileBio: string | undefined,
    instagram: string | undefined,
    tiktok: string | undefined,
    youtube: string | undefined,
    twitter: string | undefined,
    mostImportantLink: IMPORTANT_LINK_OPTIONS | string | undefined;
    card1: CardLink | undefined;
    card2: CardLink | undefined;
    card3: CardLink | undefined;
  }
};

type StoreState = IOnboardingSlice;

export type StoreSlice<T> = (
  set: SetState<StoreState>,
  get: GetState<StoreState>,
) => T;

const createOnboardingStore: StoreSlice<IOnboardingSlice> = (set, get) => {
  const setslug = (slug: string) => {
    set({ slug });
  };
  const settheme = (theme: THEME_OPTIONS) => {
    set({ theme });
  };
  const setspaceID = (spaceID: string) => {
    set({ spaceID });
  };
  const setprofilePic = (profilePic: string) => {
    set({ profilePic });
  };
  const setprofileName = (profileName: string) => {
    set({ profileName });
  };
  const setprofileBio = (profileBio: string | undefined) => {
    set({ profileBio });
  };
  const setinstagram = (instagram: string | undefined) => {
    set({ instagram });
  };
  const settiktok = (tiktok: string | undefined) => {
    set({ tiktok });
  };
  const setyoutube = (youtube: string | undefined) => {
    set({ youtube });
  };
  const settwitter = (twitter: string | undefined) => {
    set({ twitter });
  };
  const setmostImportantLink = (link: IMPORTANT_LINK_OPTIONS | string | undefined) => {
    set({ mostImportantLink: link });
  };
  const setcard1 = (card1: CardLink | undefined) => {
    set({ card1 });
  };
  const setcard2 = (card2: CardLink | undefined) => {
    set({ card2 });
  };
  const setcard3 = (card3: CardLink | undefined) => {
    set({ card3 });
  };
  const getTemplateArgs = () => {
    const {
      slug,
      theme,
      profilePic,
      profileName,
      profileBio,
      instagram,
      tiktok,
      youtube,
      twitter,
      mostImportantLink,
      card1,
      card2,
      card3,
    } = get();
    return {
      slug,
      theme,
      profilePic,
      profileName,
      profileBio,
      instagram,
      tiktok,
      youtube,
      twitter,
      mostImportantLink,
      card1,
      card2,
      card3,
    };
  };

  const state: IOnboardingSlice = {
    slug: undefined,
    theme: undefined,
    profilePic: undefined,
    profileName: undefined,
    profileBio: undefined,
    spaceID: undefined,
    instagram: undefined,
    tiktok: undefined,
    youtube: undefined,
    twitter: undefined,
    mostImportantLink: undefined,
    card1: undefined,
    card2: undefined,
    card3: undefined,
    setslug,
    settheme,
    setspaceID,
    setprofilePic,
    setprofileName,
    setprofileBio,
    getTemplateArgs,
    setinstagram,
    settiktok,
    setyoutube,
    settwitter,
    setmostImportantLink,
    setcard1,
    setcard2,
    setcard3,
  };
  return state;
};

export const useOnboardingStore = create<
StoreState,
SetState<StoreState>,
GetState<StoreState>,
Mutate<StoreApi<StoreState>, [['zustand/subscribeWithSelector', never]]>
>(
  subscribeWithSelector(
    devtools(
      (set, get) => ({
        ...createOnboardingStore(set, get),
      }),
    ),
  ),
);
